$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        cache  : false
    });

    /*
     * Set mobile Safari in standalone
     */
    if (("standalone" in window.navigator) && window.navigator.standalone) {

        // If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
        var noddy, remotes = false;

        document.addEventListener('click', function (event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if ('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes)) {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        }, false);
    }

    /*
 * Remember tabs
 */
    $('a[data-toggle="tab"]').on('click', function (e) {
        localStorage.setItem('lastTab', $(e.target).attr('href'));
    });
    //go to the latest tab, if it exists:
    var lastTab = localStorage.getItem('lastTab');

    if (lastTab) {
        $('a[href="' + lastTab + '"]').click();
    }

    window.sr = ScrollReveal({
        distance: '10px',
        useDelay: 'once',
        mobile  : false,
        vFactor : 0.50,
        duration: 100,
        delay   : 500,
        distance: '10px',
    });


    $('.content').imagesLoaded()
        .done(function (instance) {
            sr.reveal('.reveal', {
                origin: 'bottom',
            });
            sr.reveal('.reveal-rotate', {
                rotate: {x: 0, y: 0, z: 30},
            });
            log('all images successfully loaded');
        })
        .fail(function () {
            log('all images loaded, at least one is broken');
        })
        .progress(function (instance, image) {
            var result = image.isLoaded ? 'loaded' : 'broken';
            log('image is ' + result + ' for ' + image.img.src);
        });


//    window.sr = ScrollReveal({
//        //distance: '10px',
//        useDelay: 'once',
//        mobile  : false,
//        vFactor : 0.50,
//    });
//
//    sr.reveal('.reveal');


    /*
     * Flash messages
     */
    var $grid = $('.grid').isotope({});
    // filter items on button click
    $('.filter-button-group').on('click', 'button', function () {
        var filterValue = $(this).attr('data-filter');
        $grid.isotope({filter: filterValue});
    });
    // Apply Isotope after images loaded
    $('.grid').imagesLoaded(function () {
        $('.grid').isotope({
            itemSelector: '.grid-item',
        });
        //$('.grid-item').animate({opacity: 1.0}, 200);
    });


    // $('a[href*=#]:not([href=#])').click(function() {
    $('.scroll').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            || location.hostname == this.hostname) {

            var target = $(this.hash);
            target     = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    /*
     * Prevent click on disabled link
     */
    $('.disabled').click(function (e) {
        e.preventDefault();
    });

    /*
     * Initiate popovers and tootips
     */
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip({html: true});

    /*
     * Apply Affix class after scrolling
     */
//    $('#navigation2').affix({
//        offset: {
//            top: 5
//        }
//    })

    /*
 * Apply Affix
 */
    $('.navbar').affix({
        offset: {
            top: 40
        }
    })


    /*
     * Apply Matchheight
     */
    /*
     * Apply Matchheight
     */
    $('.matchheight').matchHeight();

    // $.fn.matchHeight._afterUpdate = function (event, groups) {
    //     window.sr = ScrollReveal({
    //         distance: '10px',
    //         useDelay: 'once',
    //         mobile  : false,
    //         vFactor : 0.50,
    //     });
    //
    //     sr.reveal('.reveal');
    //     sr.reveal('.reveal-rotate', {
    //         delay : 500,
    //         rotate: {x: 0, y: 0, z: 30},
    //     });
    //     sr.reveal('.reveal-up', {
    //         delay   : 500,
    //         origin  : 'bottom',
    //         distance: '20px',
    //     });
    // }

    /*
     * Flash messages
     */
    $('#flash-overlay-modal').modal();
    /*
     * Flash messages auto clear
     */
    $('.modal-auto-clear').on('shown.bs.modal', function () {
        // if data-timer attribute is set use that, otherwise use default (7000)
        var timer = $(this).data('timer') ? $(this).data('timer') : 7000;
        $(this).delay(timer).fadeOut(200, function () {
            $(this).modal('hide');
        });
    })
    /*
     * Initiate colorbox
     */
    $('a.gallery').colorbox({
        rel           : 'gal',
        slideshow     : true,
        slideshowSpeed: 5000,
        maxHeight     : '90%',
        maxWidth      : '90%'
    });

    /*
     * Initiate Swiper
     */
    var mySwiper = new Swiper('.swiper-container', {
        autoplay: 5000,
        speed   : 600,
        loop    : true,
        parallax: true,
        effect  : 'fade',

        // If we need pagination
        pagination         : '.swiper-pagination',
        paginationClickable: true,

        //// Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        //
        //// And if we need scrollbar
        //scrollbar: '.swiper-scrollbar',
    })

    // $(".swiper-container").each(function(index, element){
    //     var $this = $(this);
    //     $this.addClass("instance-" + index);
    //     $this.find(".swiper-button-prev").addClass("btn-prev-" + index);
    //     $this.find(".swiper-button-next").addClass("btn-next-" + index);
    //     var swiper = new Swiper(".instance-" + index, {
    //         // your settings ...
    //         nextButton: ".btn-next-" + index,
    //         prevButton: ".btn-prev-" + index
    //     });
    // });

    /*
     * Remember tabs
     */
    $('a[data-toggle="tab"]').on('click', function (e) {
        localStorage.setItem('lastTab', $(e.target).attr('href'));
    });
    //go to the latest tab, if it exists:
    var lastTab = localStorage.getItem('lastTab');

    if (lastTab) {
        $('a[href="' + lastTab + '"]').click();
    }

    /*
     * Submit demo form
     */
    $("#newsletterSubscribe").on("click", function (e) {
        e.preventDefault();

        var data    = $('#newsletterform').serializeArray();
        var loadUrl = $('#newsletterform').attr('action');

        $.ajax({
            url  : loadUrl,
            type : "POST",
            cache: false,
            data : $('#newsletterform').serializeArray(),

            success: function (response) {
                $('#newsletter').addClass('text-danger')
                $('#newsletter').html('<i class="fa fa-circle-o-notch fa-spin fa-2x"></i>');
                $('#newsletter').html('<h3>' + response.header + '</h3><p>' + response.message + '</p>');
            },

            error: function (data) {
                $('#newsletter').removeClass('text-danger')

                var errors = JSON && JSON.parse(data.responseText) || $.parseJSON(data.responseText); //$.parseJSON(data.responseText);
                $.each(errors, function (index, value) {
                    if ($('input[name="' + index + '"]').next('span').length)
                        $('#' + index).text(value);
                    else
                        $('input[name="' + index + '"]').after("<span id='" + index + "' class='help-block small danger'>" + value + "</span>");
                });
            }

        });
    });

    // var popupSize = {
    //     width : 500,
    //     height: 300
    // };
    //
    // $(document).on('click', '.social-buttons a', function (e) {
    //
    //     var
    //         verticalPos   = Math.floor(($(window).width() - popupSize.width) / 2),
    //         horizontalPos = Math.floor(($(window).height() - popupSize.height) / 2);
    //
    //     var popup = window.open($(this).prop('href'), 'social',
    //         'width=' + popupSize.width + ',height=' + popupSize.height +
    //         ',left=' + verticalPos + ',top=' + horizontalPos +
    //         ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');
    //
    //     if (popup) {
    //         popup.focus();
    //         e.preventDefault();
    //     }
    //
    // });

    function log(msg) {
        try {
            console.log(msg);
        } catch (e) {
        }
    }

});